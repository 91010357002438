<template>
  <div style="height: 100%;">
    <comp-table :tableData="tableData" :tableHeader="tableHeader" :page='current' :pageSize='pageSize' :total='total' @current-events="getList" @save-current="savePage"
    height="84%" v-loading='isLoading' v-if="initStatus" @size-events='changeSize'>
    </comp-table>
    <nodata :message="'暂无相关账套'" h="400" v-else />
    <delte-dialog :visible='deleteVis' :cancelDialog='cancelDialog' :confirmDialog='confirmDialog' :rowData='rowData' :isdeling='isdeling'/>
  </div>
</template>

<script>
  import delteDialog from './dialog/delte';
  export default {
    props: ['status', 'value','roleName'],
    name: "complexTable",
    components: {
      delteDialog,
    },
    data() {
      return {
        tableData: [], // 请求到的表格数据
        tableHeader: [
          // 表头信息
          {
            prop: "img",
            width: 60,
            img: true,
          },
          {
            prop: "name",
            center: 'left',
            label: "单位名称",
            title: true,
          },
          {
            currentPeriod: true,
            label: "当前记账年月",
          },
          {
            date: true,
            label: "账套启用年月",
          },
          {
            prop: "accountingStandard",
            accounting: true,
            label: "会计准则",
          },
          {
            prop: "examine",
            status: true,
            label: "凭证审核",
          },
          {
            prop: "roleName",
            label: "账套权限",
          },
          {
            prop: "operation",
            label: "操作",
            center: 'center',
            minWidth: this.status ? 32 : 40,
            operation: [
                { name: "编辑", clickFun: this.editDomain },
                { name: "删除", clickFun: this.showCancel }, // makeSure: true,
              ]
          },
        ],
        multipleSelection: [],

        //分页查询
        current: 1, //当前页数
        data: [],
        total: 0,
        pageSize: 20,
        isLoading: false,
        initStatus: true,

        //提示窗
        deleteVis: false,
        isdeling : false,
        rowData: {},
      };
    },
    methods: {
      doInitAccountList(value){
        this.accountList=value;
        this.getCurrentCompany();
      },
      // 所有实例列表
      savePage(val) {
        this.current = val;
      },
      changeSize(val){
        this.pageSize = val;
        this.getList();
      },
      getList(val) {
        this.isLoading = true
        this.$store.dispatch("FindCompanyList", {
          name: this.value,
          userId: this.getToken('userId'),
          index: val ? val : this.current,
          rows: this.pageSize,
        }).then(res => {
          this.isLoading = false;
          if (res.success) {
            this.initStatus = true;
            this.total = res.data.count;
            this.tableData = res.data.list;
            // if(res.data.count > 0){this.setToken('asId', res.data.list[0].id);}
          } else {
            this.initStatus = false
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          this.isLoading = false
        })
      },
      sortEvents(type) {
        console.log(type);
      },
      more() {
        this.$router.push('/access/apply/server')
      },
      showCancel(item) {
        this.rowData = item;
        this.deleteVis = true;
      },
      cancelDialog() {
        this.deleteVis = false;
      },
      confirmDialog() {
        this.deleteDomain(this.rowData)
      },
      // 删除
      deleteDomain(item) {
        this.isdeling = true;
        this.$store.dispatch("DelAccount", { userId: this.getToken('userId'), asId: item.id },).then(res => {
          if (res.success) {
            this.isdeling = false;
            if(this.total == '1'){
              this.setToken('asId','');
              // localStorage.setItem('sideLink', '/home');
              setTimeout(() => {
                window.location.reload();
              }, 1000)
            }else{
              let maxPage = Math.ceil((this.total - 1) / this.pageSize)
              if (this.current > maxPage) {
                this.current = maxPage
              }
              this.getList(this.current);
              einvAlert.success("提示",'删除成功');
              this.deleteVis = false;
            }
          } else {
            this.isdeling = false;
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
        })
      },
      editDomain(row) {
        this.$router.push({
          path: "/install/company/edit",
          query: { id: row.id }
        })
        // this.$router.push('/install/company/edit?id='+row.id)
      },
    },
    mounted() {
      this.getList(); //实例列表
    },
  };
</script>

<style scoped>
  .el-form-item {
    margin-bottom: 10px;
  }
  .filter-item {
    margin-left: 12px;
    width: 130px;
    margin-bottom: 10px;
  }
  .btn-item {
    height: 32px;
    margin-left: 12px;
    vertical-align: super;
  }
  .el-popper[x-placement^="bottom"] {
    margin-top: 12px;
  }
</style>
