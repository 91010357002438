<template>
  <div class="app-container">
    <nav>
      <div class="tips">
        <el-button @click="toPath" type="primary" v-auth='"company:add"'>新建公司</el-button>
        <el-button @click="imports" type="primary" style="margin-left: 10px" v-auth='"company:quick"'>快捷导票</el-button>
      </div>
      <div class="operation">
        <el-input placeholder="搜索..." v-model="queryValue" class="inputSearch">
          <i class="iconfont iconicon-30" slot="suffix" @click="searchList" v-auth='"company:lookup"'></i>
        </el-input>
        <!-- <i class='iconfont iconicon1-08' :class="{validate: validate == 'box' }" @click="changeLayout('box')" />
        <i class='iconfont iconicon1-09' :class="{validate: validate == 'table'}" @click="changeLayout('table')" />
        <i class='iconfont iconicon1-10' @click="toDel" /> -->
      </div>
    </nav>

    <div class="filter-container" v-if="validate == 'table'">
      <books-table :status='true' :value='queryValue' ref="booksTable" :roleName='roleName' @doInitAccountList="doInitAccountList" />
    </div>
    <div class="content-container" v-else>
      <split-box :value='queryValue' ref="splitBox"  @doInitAccountList="doInitAccountList" @doChangeMenuDisabled="doChangeMenuDisabled"/>
    </div>
    <!-- <import-dialog :visible='importVis' :cancelDialog='importCancel' :importType="importType"/> -->
     <import-dialog :visible='importVis' :cancelDialog='importCancel' :requestFrom="requestFrom" :invoiceType="invoiceType" :isOpenEinv="isOpenEinv"/>
  </div>
</template>

<script>
  import BooksTable from './modules/booksTable'
  import SplitBox from './modules/splitBox'
  import importDialog from '@/components/importInvoice'
  // import importDialog from '@/components/invoice/import'
  import { mapGetters } from 'vuex'
  export default {
    components: { BooksTable, SplitBox,importDialog },
    data() {
      return {
        validate: this.getToken('validate') || 'box',
        queryValue: '',
        importVis: false,
        // importType: '0',
        requestFrom:"einv",
        isOpenEinv:"0",
        invoiceType:"0",
      }
    },
    computed: {
      ...mapGetters([
        'accountTotal','roleName',
      ])
    },
    methods: {
      doInitAccountList(value){
        this.$emit('doInitAccountList',value);
      },
      doChangeMenuDisabled(value){
        this.$emit('doChangeMenuDisabled',value);
      },
      searchList(){
        this.validate == 'box' ? this.$refs.splitBox.getList() : this.$refs.booksTable.getList();
      },
      toPath() {
        this.$router.push('/install/company/add')
      },
      // changeLayout(value) {
      //   this.setToken('validate', value)
      //   this.validate = value; //默认 盒子布局
      // },
      // toDel() {
      //   this.$router.push('/install/company/del')
      // },
      // imports(){
      //   this.importVis = true;
      // },
      imports(){
        const _this = this
        this.setToken('preUploadClientId',this.requestFrom+"_"+this.getToken('companyId')+"_"+this.invoiceType+'_'+this.getToken('userId')); //保存传参标记 clientId
        this.$store.dispatch('IsHasTask',{
          clientId:this.getToken('preUploadClientId')
        }).then(res => {
          if(res.success){
            if(res.data.isHasTask){
              einvAlert.confirm(function(){_this.confirmHandle(res.data.toPath,_this.invoiceType)}
                  ,"您上次的发票导入操作尚未完成，是否需要继续上次操作？"
                  ,'确认'
                  ,'取消'
                  ,function(){_this.cancelHandle()})
            }else{
              this.importVis = true;
            }
          }else {
            res ? einvAlert.error("提示",res.msg||res.message) : einvAlert.error("提示",'未知异常，稍后再试')
          }
        }).catch(err =>{
          err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常，稍后再试')
        })
      },
            /* 确认操作，继续上次操作 */
      confirmHandle(toPath,invoiceType){
        /* 页面跳转到编辑页面 */
        if(toPath=="1"){//发票矫正页面
          this.$router.push({
            path:'/install/company/invoiceInfo',
            query:{
              /* 业务类型  1-进项，2-销项 */
              invoiceType:invoiceType,
              // visible:true
            }
          })
          this.setToken('uploadListShow',true)
        }else{
          this.$router.push({
            path:'/install/company/invoiceInfo',
            query:{
              /* 业务类型  1-进项，2-销项 */
              invoiceType:invoiceType,
              // visible:false
            }
          })
          this.setToken('uploadListShow',false)
        }

      },
      /* 取消上次操作，清除上次数据，弹窗-上传图片 (已完成) */
      cancelHandle(){
        /* 调清除上次数据接口 */
        this.$store.dispatch('PostCancelUpload',{
          clientId:this.getToken('preUploadClientId')
        }).then(res =>{
            if(!res.success){
              einvAlert.error("提示",res.msg||res.message)
              }
        }).catch(err =>{
          err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常，稍后再试')

        })
        this.importVis = true;
      },
      importCancel(){
        this.importVis = false;
      },
      getList(){}
    },
    created() {

    }
  }
</script>

<style lang='less' scoped>
  nav {
    height: 60px;
    display: flex;
    padding: 0 18px;
    // line-height: 60px;
    align-items: center;
    background-color: #fff;
    justify-content: space-between;
    .tips {
      font-size: 16px;
      span {
        margin-left: 18px;
      }
      .num {
        color: #4f71ff;
        font-style: normal;
      }
    }
    .operation {
      display: flex;
      .inputSearch {
        margin-right: 10px;
        /deep/ .el-input__inner {
          border-radius: 21px !important;
          height: 35px !important;
          // line-height: 35px;
        }
        /deep/ .el-input__suffix {
          right: 5px;
          top: 10px;
          color: #4f71ff;
        }
      }
      i {
        padding: 10px 8px;
        cursor: pointer;
      }
    }
  }
  .filter-container {
    height: calc(100% - 5.21rem);
  }
  .validate {
    color: #4f71ff;
  }
</style>